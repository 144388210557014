import MediaContainer from '@/components/MediaContainer'
import { NpContainer } from '../NPContainer'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Video from '../video'
import React from 'react'
// import { images } from '../../images/'
import mobile from '@/images/dammans_fyr_ocean_m2.jpg'

const Hero = ({ data, sectionPageData }) => {
  const media = data.media.data

  const router = useRouter()

  const { video } = router.query

  let isHomePage = useRouter().asPath === '/'

  const [opacities, setOpacities] = useState([])

  const [sliderRef] = useKeenSlider(
    {
      slides: media.length,
      loop: true,
      defaultAnimation: {
        duration: 5000,
      },

      detailsChanged(s) {
        const new_opacities = s.track.details.slides.map(
          (slide) => slide.portion
        )
        setOpacities(new_opacities)
      },
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 4000)
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
      },
    ]
  )

  const myPoster = (media) => {
    return media.url.substr(0, media.url.lastIndexOf('.')) + '.jpg'
  }

  return (
    <>
      <MediaContainer
        ratioProps={`pb-[120%] sm:pb-[50%] lg:pb-[45%%] xl:pb-[38%] bigScreen:pb-[30%] w-screen bigScreen:w-full relative 
        `}
        innerProps={`max-w-none ${isHomePage && 'bigScreen:max-w-hero'}`}
        outerProps={`m-0 p-0 ${isHomePage && 'bigScreen:mt-64'}`}
      >
        <div
          className={`absolute bottom-0 left-0 right-0 top-0 flex h-64 w-full ${
            isHomePage && 'flex bigScreen:hidden'
          }`}
          style={{
            zIndex: '9',
            backgroundBlendMode: 'multiply',
            backgroundImage:
              'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0))',
          }}
        />
        {!isHomePage && (
          <div
            className={`absolute bottom-0 left-0 flex h-full w-full ${
              isHomePage && 'flex bigScreen:hidden'
            }`}
            style={{
              zIndex: '8',
              backgroundBlendMode: 'multiply',
              backgroundImage:
                'linear-gradient(16deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 16%)',
            }}
          />
        )}
        {!isHomePage && (
          <div
            className={`not-prose absolute bottom-0 left-0 z-[20] ${
              isHomePage && 'bigScreen:hidden'
            }`}
          >
            <h1 className="z-[10] mb-4 ml-4 font-prata text-sm tracking-wide text-white sm:mb-8 sm:ml-8 md:text-lg xl:text-lg ">
              {sectionPageData.shortName}
            </h1>
          </div>
        )}

        {media.length > 1 ? (
          <div ref={sliderRef} className="relative h-full overflow-hidden ">
            {media.map((src, idx) => (
              <div
                key={idx}
                className="absolute top-0 h-full w-full"
                style={{ opacity: opacities[idx] }}
              >
                {src.attributes.mime !== 'video/mp4' ? (
                  <Image
                    sizes="100vw"
                    alt={media[0].attributes.alternativeText}
                    fill
                    src={src.attributes.url}
                    style={{ objectFit: 'cover' }}
                    priority
                  />
                ) : video === 'true' ? (
                  <Video media={src.attributes} />
                ) : (
                  <Image
                    sizes="100vw"
                    alt={media[0].attributes.alternativeText}
                    fill
                    src={`${myPoster(src.attributes)}`}
                    style={{ objectFit: 'cover' }}
                    priority
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="absolute top-0 h-full w-full overflow-hidden">
            {media[0]?.attributes?.mime !== 'video/mp4' ? (
              <>
                {isHomePage && (
                  <div className="absolute top-0 block h-full w-full sm:hidden">
                    <Image
                      sizes="100vw"
                      alt={media[0].attributes.alternativeText}
                      fill
                      src={mobile}
                      style={{ objectFit: 'cover' }}
                      priority
                    />
                  </div>
                )}
                <div
                  className={`absolute top-0 ${
                    isHomePage ? 'hidden' : 'block'
                  } h-full w-full sm:block `}
                >
                  <Image
                    alt={media[0]?.attributes?.alternativeText}
                    fill
                    src={media[0]?.attributes?.url}
                    style={{ objectFit: 'cover' }}
                    priority
                  />
                </div>
              </>
            ) : video === 'true' ? (
              <Video media={media[0].attributes} />
            ) : (
              <Image
                sizes="100vw"
                // sizes={'100vw'}
                alt={''}
                fill
                src={`${myPoster(media[0].attributes)}`}
                style={{ objectFit: 'cover' }}
                priority
              />
            )}
          </div>
        )}
      </MediaContainer>

      <NpContainer
        innerProps="w-full"
        outerProps="w-full -mb-3  items-center py-7 px-6 xs:px-8 sm:px-14 md:px-0 sm:py-10 lg:py-14 xl:py-20"
      >
        <div className="mx-auto flex max-w-[300px] items-start justify-center text-center sm:max-w-[420px] xl:max-w-[500px] ">
          {isHomePage ? (
            <h1 className="max-w-[260px] px-4 text-[1.7rem] leading-9 dark:text-dammanBg sm:max-w-[300px] sm:px-0 lg:text-3xl bigScreen:max-w-[380px] bigScreen:text-dammanText">
              {data.heading}
            </h1>
          ) : (
            <h2 className="font-prata dark:text-dammanBg lg:text-2xl xl:text-3xl bigScreen:text-dammanText">
              {data.heading}
            </h2>
          )}
        </div>

        <div className="mx-auto my-5 h-[1px] w-full max-w-[80px] bg-dammanText dark:bg-white sm:max-w-[100px] md:max-w-[140px] lg:mb-10 lg:mt-8  bigScreen:block" />
        <p className="mx-auto flex max-w-xl justify-center pl-2 pr-2 text-center font-[500] lg:-mt-2 lg:text-[1.1rem] lg:leading-7 xl:max-w-2xl bigScreen:text-lg bigScreen:leading-8">
          {data.preamble}
        </p>
      </NpContainer>
    </>
  )
}

export default Hero
